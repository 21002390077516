import SliderSeeker from "../../components/ui/SliderSeeker";
import { maximumBibleFontSize, minimumBibleFontSize } from "../../contexts/Settings";
import useSettings from "../../hooks/useSettings";
import { IVerse } from "../../types";
import Verse from "../Reading/Chapter/Verse";
import SettingCard from "./SettingCard";

const dummyVerse: IVerse = {
    content: "In the beginning God created the heaven and the earth.",
    index: "1",
    LCComents: [
        "“In the beginning God. . .” The Scriptures don’t try to prove the existence of God. They begin with the truth that everyone knows there is a God. This is exactly the point of Romans 1:18-20. There is an intuitive knowledge of God on the inside of every single person who has ever lived. We shouldn’t argue with anyone about His existence. We just go past their heads to their hearts and touch the part of them that knows there is a God.",
    ],
    LFTComments: [],
};

const fontSizeRange = maximumBibleFontSize - minimumBibleFontSize;

const FontSize = () => {
    const { fontSize, setFontSize } = useSettings();

    const onFontSizeChange = (percentage: number) => {
        const fs = minimumBibleFontSize + fontSizeRange * percentage;
        setFontSize(fs);
    };

    return (
        <SettingCard title="Font Size">
            <div>
                <div className="flex items-center justify-between mb-2">
                    <span className="text-xs">A</span>
                    <span className="">A</span>
                    <span className="text-xl">A</span>
                    <span className="text-2xl">A</span>
                </div>
                <SliderSeeker
                    onChange={onFontSizeChange}
                    initialPercentage={(fontSize - minimumBibleFontSize) / fontSizeRange}
                />
            </div>

            <div className="mx-2 mt-6">
                <Verse verse={dummyVerse} shouldExpand={true} showExpandCollapseCtrl={false} fontSize={fontSize} />
            </div>
        </SettingCard>
    );
};

export default FontSize;
