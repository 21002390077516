export const PATH = {
  root: '/',
  menu: '/menu',
  calendar: '/calendar',
  instructions: '/instructions',
  settings: '/settings',
};

export const instructionSlide2QueryString = 'slide=2';

export const EXTERNAL_LINKS = {
  awmi: 'https://www.awmi.net',
  awmiMail: 'mailto:info@awmi.net',
  awmiPhone: 'tel:+17196351111',

  contactUs: 'https://www.awmi.net/contact-us/',
  purchaseLC:
    'https://www.awmi.net/lc/?SOURCE=30W00G032&utm_source=awmi.net&utm_medium=internal&utm_campaign=bibleplan.awmi.net&utm_content=living-commentary',
  donate:
    'https://www.awmi.net/give/?SOURCE=30W00G032&utm_source=awmi.net&utm_medium=internal&utm_campaign=bibleplan.awmi.net&utm_content=give',
  freeMultimediaTeachings: 'https://www.awmi.net/popular/',
  partnerWithAWMI:
    'https://store.awmi.net/p-682-grace-partnership.aspx?SOURCE=30W00G032&utm_source=awmi.net&utm_medium=internal&utm_campaign=bibleplan.awmi.net&utm_content=partnership',
  gospelTruthTv: 'https://www.gospeltruth.tv/',

  // social media
  facebook: 'https://www.facebook.com/AWMinistries/',
  twitter: 'https://twitter.com/andrewwommack/',
  instagram: 'https://www.instagram.com/andrewwommack/',
};
