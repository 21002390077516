import { NavLink } from 'react-router-dom';
import CallForPrayer from './CallForPrayer';
import { menuItems } from './menuItems';

const MenuPanel = () => {
  return (
    <div className={`w-full min-h-screen pt-24 bg-primary-darker z-20 pb-8`}>
      {menuItems.map(menuItem => {
        const menuItemClassName =
          'w-full block mt-2 text-center p-2 text-primary-lighter text-3xl hover:bg-white';

        return menuItem.isExternal ? (
          <a
            key={menuItem.to}
            href={menuItem.to}
            className={menuItemClassName}
            target="_blank"
            rel="noreferrer"
          >
            {menuItem.name}
          </a>
        ) : (
          <NavLink
            key={menuItem.to}
            to={menuItem.to}
            className={menuItemClassName}
          >
            {menuItem.name}
          </NavLink>
        );
      })}

      <CallForPrayer color="white" />
    </div>
  );
};

export default MenuPanel;
