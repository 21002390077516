import useSettings from '../../../hooks/useSettings';
import { IChapter } from '../../../types';
import Verse from './Verse';

interface ChapterProps {
  allExpanded: boolean;
  book: String;
  chapter: IChapter;
}

const Chapter = ({ allExpanded, book, chapter }: ChapterProps) => {
  const { fontSize } = useSettings();

  return (
    <div className="px-6 py-2">
      <div className=" mt-4 mb-10 text-center text-2xl text-primary-light  ">
        {book.toUpperCase()} {chapter.index}
      </div>

      <div className="flex flex-col gap-8">
        {chapter.verses.map(verse => (
          <Verse
            key={verse.index}
            verse={verse}
            shouldExpand={allExpanded}
            fontSize={fontSize}
          />
        ))}
      </div>
    </div>
  );
};

export default Chapter;
