import Spinner from './Spinner';

interface ButtonProps {
  /**
   * title or lable of the button
   */
  children: React.ReactNode;
  /**
   * different looks of the button.
   */
  variant: 'filled' | 'outlined';
  /**
   * text color
   */

  /**
   * click handler
   */
  onClick?: () => void;
  /**
   * Optional additional class name to customize it further
   */
  className?: string;
  /**
   * disalbes the button
   */
  disabled?: boolean;
  /**
   * shows loading icon
   */
  pending?: boolean;
  /**
   * pending messag
   */
  pendingMessage?: string;
  /**
   * for anchors
   */
  to?: string;
  [x: string]: any;
}

const classNamesMap: { [x: string]: any } = {
  outlined: {
    primary: {
      normal:
        ' border border-primary-light text-primary-light hover:bg-gray-100 ',
      disabled: ' border border-primary-lighter/70 text-primary-lighter ',
    },
    white: {
      normal: ' border border-white text-white  ',
      disabled: ' border border-white text-white ',
    },
  },
  filled: {
    primary: {
      normal: ' bg-primary-light text-white  hover:bg-primary ',
      disabled: ' bg-primary-lighter/70 text-white ',
    },
    white: {
      normal: ' bg-white text-primary hover:bg-gray-100 ',
      disabled: ' bg-gray-100 text-primary ',
    },
  },
};

const Button = ({
  children,
  variant = 'outlined',
  color = 'primary',
  className = '',
  onClick,
  disabled,
  pending,
  pendingMessage,
  to,
  ...props
}: ButtonProps) => {
  let classNames =
    ' rounded-[1.25rem] flex items-center justify-center py-3 font-avenir-demibold text-lg h-fit ';

  classNames +=
    classNamesMap[variant][color][disabled || pending ? 'disabled' : 'normal'];

  classNames += ` ${className}`;

  const ChildComponent = pending ? (
    <>
      <Spinner className="inline-block mr-2 w-4 h-4  fill-primary" />
      {pendingMessage || 'Loading...'}
    </>
  ) : (
    children
  );

  const isAnchorComponent = !!to && !onClick;

  if (isAnchorComponent) {
    return (
      <a href={to} type="button" className={classNames} {...props}>
        {ChildComponent}
      </a>
    );
  } else {
    return (
      <button
        type="button"
        className={classNames}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {ChildComponent}
      </button>
    );
  }
};

export default Button;
