import { useEffect, useRef } from 'react';
import { months } from '../../data/months';
import { MonthData } from '../../types';
import MonthDays, {
  DATE_CELL_BOTTOM_BORDER_WIDTH,
  DATE_CELL_HEIGHT,
} from './MonthDays';

interface DaysListProps {
  selectedMonth: MonthData;
  selectedDate: number;
  onSelectDate: (month: MonthData, date: number) => void;
}

const monthsPositions: Array<{ scrollBottom: number; scrollTop: number }> = [];

for (let i = 0; i < months.length; i++) {
  const scrollTop = i > 0 ? monthsPositions[i - 1].scrollBottom : 0;
  monthsPositions.push({
    scrollTop,
    scrollBottom:
      scrollTop +
      months[i].numberOfDays * DATE_CELL_HEIGHT +
      DATE_CELL_BOTTOM_BORDER_WIDTH,
  });
}

const dayListTopOffset = 200;

const DaysList = ({
  selectedMonth,
  selectedDate,
  onSelectDate,
}: DaysListProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const selectedMonthShortNameRef = useRef(selectedMonth.shortName);
  const isScrollingRef = useRef<NodeJS.Timeout | null>(null);
  const ignoreScrollRef = useRef(false);

  useEffect(() => {
    if (selectedMonth && !ignoreScrollRef.current && listRef.current) {
      const monthIndex = months.findIndex(
        month => month.shortName === selectedMonth.shortName
      );

      const scrollTo = Math.min(
        monthsPositions[monthIndex].scrollTop +
          Math.max(selectedDate - 1, 0) * DATE_CELL_HEIGHT,
        monthsPositions[monthIndex].scrollBottom - dayListTopOffset
      );

      const listViewPortHeight = listRef.current.getBoundingClientRect().height;

      const isTheDateCellAlreadyVisible =
        scrollTo > listRef.current.scrollTop &&
        scrollTo + DATE_CELL_HEIGHT <
          listRef.current.scrollTop + listViewPortHeight;

      if (!isTheDateCellAlreadyVisible) {
        listRef.current?.scrollTo(listRef.current?.scrollLeft, scrollTo);
      }
    }
    selectedMonthShortNameRef.current = selectedMonth.shortName;
  }, [selectedMonth, selectedDate]);

  useEffect(() => {
    const onScroll = () => {
      if (isScrollingRef.current) {
        clearTimeout(isScrollingRef.current);
        ignoreScrollRef.current = true;
      }

      isScrollingRef.current = setTimeout(() => {
        ignoreScrollRef.current = false;
      }, 66);

      const scrolledTop = dayListTopOffset + (listRef.current?.scrollTop || 0);

      const selectedMonthIndex = monthsPositions.findIndex(
        monthPosition =>
          monthPosition.scrollTop < scrolledTop &&
          monthPosition.scrollBottom > scrolledTop
      );

      if (
        selectedMonthIndex !== -1 &&
        months[selectedMonthIndex].shortName !==
          selectedMonthShortNameRef.current
      ) {
        onSelectDate(months[selectedMonthIndex], 1);
        ignoreScrollRef.current = true;
      }
    };
    listRef.current?.addEventListener('scroll', onScroll);
    const listRefCopy = listRef.current;
    return () => listRefCopy?.removeEventListener('scroll', onScroll);
  }, [onSelectDate]);

  return (
    <div
      className="flex-grow bg-primary-darker h-full overflow-auto"
      ref={listRef}
    >
      {months.map(month => (
        <MonthDays
          key={month.shortName}
          month={month}
          isSelectedMonth={selectedMonth.shortName === month.shortName}
          selectedDate={selectedDate}
          onSelectDate={(date: number) => {
            onSelectDate(month, date);
          }}
        />
      ))}
    </div>
  );
};

export default DaysList;
