import { MonthData } from '../types';

const thisYear = new Date().getFullYear();
const isThisLeapYear =
  (thisYear % 4 === 0 && thisYear % 100 !== 0) || thisYear % 400 === 0;

export const months: MonthData[] = [
  {
    name: 'JANUARY',
    shortName: 'JAN',
    numberOfDays: 31,
  },
  {
    name: 'FEBRUARY',
    shortName: 'FEB',
    numberOfDays: isThisLeapYear ? 29 : 28,
  },
  {
    name: 'MARCH',
    shortName: 'MAR',
    numberOfDays: 31,
  },
  {
    name: 'APRIL',
    shortName: 'APR',
    numberOfDays: 30,
  },
  {
    name: 'MAY',
    shortName: 'MAY',
    numberOfDays: 31,
  },
  {
    name: 'JUNE',
    shortName: 'JUN',
    numberOfDays: 30,
  },
  {
    name: 'JULY',
    shortName: 'JUL',
    numberOfDays: 31,
  },
  {
    name: 'AUGUST',
    shortName: 'AUG',
    numberOfDays: 31,
  },
  {
    name: 'SEPTEMBER',
    shortName: 'SEP',
    numberOfDays: 30,
  },
  {
    name: 'OCTOBER',
    shortName: 'OCT',
    numberOfDays: 31,
  },
  {
    name: 'NOVEMBER',
    shortName: 'NOV',
    numberOfDays: 30,
  },
  {
    name: 'DECEMBER',
    shortName: 'DEC',
    numberOfDays: 31,
  },
];

// date helpers
export const isLastDayOfTheYear = (monthShortName: string, date: number) => {
  return monthShortName === 'DEC' && date === 31;
};

export const isFirstDayOfTheYear = (monthShortName: string, date: number) => {
  return monthShortName === 'JAN' && date === 1;
};

export const getDateOfTheYear = (monthShortName: string, date: number) => {
  let totalDates = 0;
  for (let month of months) {
    if (month.shortName === monthShortName) {
      totalDates += date;
      break;
    }
    totalDates += month.numberOfDays;
  }

  return totalDates;
};

export const getMonthIndex = (monthShortName: string) => {
  return months.findIndex(month => month.shortName === monthShortName);
};

const padWithZero = (num: number, count: number) => {
  const padded = `${new Array(count).fill('0').join('')}${num}`;
  return padded.substring(padded.length - count);
};

export const getMMDD = (month: MonthData, date: number) => {
  // it returns MMDD format of a day. example : December 21 ==> 1221;
  const monthIndex = months.findIndex(m => m.shortName === month.shortName) + 1;
  const formatted = `${padWithZero(monthIndex, 2)}${padWithZero(date, 2)}`;
  return formatted;
};
