import { MinusFilledIcon, PlusOutlinedIcon } from "../../../assets/icons";

interface ToggleVerseCommentaryProps {
    isExpanded: boolean;
    disabled?: boolean;
    toggle: () => void;
}

export const ToggleVerseCommentary = ({ isExpanded, disabled = false, toggle }: ToggleVerseCommentaryProps) => {
    return (
        <button
            className=" w-[84px] rounded-lg pl-5 pr-1 relative border-[1.5px] border-primary h-10 flex items-center justify-end disabled:opacity-0 disabled:border-opacity-0"
            disabled={disabled}
            onClick={toggle}
        >
            <div className="absolute top-1 w-7 h-7 -left-3.5 bg-white p-0">
                {isExpanded && !disabled ? <MinusFilledIcon /> : <PlusOutlinedIcon />}
            </div>

            <div className="shrink-0 text-xs text-primary font-avenir-demibold leading-3">
                Andrew's
                <br />
                Notes
            </div>
        </button>
    );
};
