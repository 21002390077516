import RangeSlider from "../../components/ui/RangeSlider";
import { browserName } from "react-device-detect";
import useScreenSize from "../../hooks/useScreenSize";

interface SwipeCommentaryTogglerProps {
    isExpanded: boolean;
    setIsExpanded(isExpanded: boolean): void;
}

const SwipeCommentaryToggler = ({ isExpanded, setIsExpanded }: SwipeCommentaryTogglerProps) => {
    const { screenSize } = useScreenSize();
    return (
        <div className="w-full flex justify-center mt-11 mb-10 ">
            {(browserName === "Firefox" || browserName === "Safari") && screenSize > 768 ? (
                <label className="relative inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        onChange={() => setIsExpanded(!isExpanded)}
                    />
                    <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-900 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                    <div className="ml-3 text-sm font-medium">
                        {isExpanded ? "Close" : "Open"} All of Andrew's Notes
                    </div>
                </label>
            ) : (
                <RangeSlider
                    width="330px"
                    height="36px"
                    valuesCount={2}
                    value={isExpanded ? 1 : 0}
                    onChange={(index: number) => {
                        setIsExpanded(index === 1);
                    }}
                    sliderBaseClassName={`rounded-full border border-primary-light ${
                        isExpanded ? " bg-primary-light " : " bg-white "
                    }`}
                    sliderClassName="rounded-full bg-white border border-primary-light shadow-lg "
                    SliderBaseComponent={
                        isExpanded ? (
                            <div
                                className={" font-avenir-bold flex items-center h-full px-3 text-white justify-start "}
                            >
                                Swipe to Close All Andrew’s Notes
                            </div>
                        ) : (
                            <div
                                className={
                                    " font-avenir-bold flex items-center h-full px-3 text-primary-light justify-end "
                                }
                            >
                                Swipe to Open All Andrew’s Notes
                            </div>
                        )
                    }
                />
            )}
        </div>
    );
};

export default SwipeCommentaryToggler;
