import logo from '../../assets/awm-logo.png';

interface TopbarProps {
  isSideBarOpen: boolean;
  openSideBar: () => void;
}

const Topbar = ({ isSideBarOpen, openSideBar }: TopbarProps) => {
  return (
    <nav className="w-full py-2 px-4">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <a href="/" className="flex items-center">
          <img
            src={logo}
            className="h-6 mr-3 w-[80px] h-auto"
            alt="Flowbite Logo"
          />
        </a>
        <button
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg "
          aria-controls="navbar-hamburger"
          aria-expanded="false"
          onClick={openSideBar}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-7 h-7 stroke-primary-darker fill-none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default Topbar;
