interface TestamentTabProps {
  isOldTestament: boolean;
  setIsOldTestament: (isOldTestament: boolean) => void;
}

const TestamentTab = ({
  isOldTestament,
  setIsOldTestament,
}: TestamentTabProps) => {
  const commonClassNames =
    ' flex-1 pb-5 text-center font-avenir-demibold text-lg cursor-pointer ';
  const activeClassNames = ' border-b-2 border-primary text-black';
  const inActiveClassNames = ' opacity-40 text-black ';

  return (
    <div className="flex align-center gap-2 mt-6  ">
      <div
        className={`${commonClassNames} ${
          isOldTestament ? activeClassNames : inActiveClassNames
        }`}
        onClick={() => {
          setIsOldTestament(true);
        }}
      >
        OLD TESTAMENT
      </div>
      <div
        className={`${commonClassNames} ${
          !isOldTestament ? activeClassNames : inActiveClassNames
        }`}
        onClick={() => {
          setIsOldTestament(false);
        }}
      >
        NEW TESTAMENT
      </div>
    </div>
  );
};

export default TestamentTab;
