import CrossIcon from '../../assets/icons/CrossIcon';

interface SidebarScaffoldProps {
  children: React.ReactNode;
  isOpen: boolean;
  btnCloseTheme?: 'light' | 'dark';
  onClose: () => void;
}

const SidebarScaffold = ({
  children,
  isOpen,
  btnCloseTheme,
  onClose,
}: SidebarScaffoldProps) => {
  const isLight = btnCloseTheme === 'light';
  return (
    <main
      className={
        ' fixed h-full overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
        (isOpen
          ? ' transition-opacity opacity-100 duration-300 translate-x-0  '
          : ' transition-x delay-300 opacity-0 translate-x-full  ')
      }
    >
      <section
        className={
          ' w-full max-w-md right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
          (isOpen ? ' translate-x-0 ' : ' translate-x-full ')
        }
      >
        <div className="relative h-full w-full overflow-auto">
          <button onClick={onClose} className="absolute right-8 top-8">
            <CrossIcon stroke={isLight ? 'white' : 'black'} />
          </button>
          {children}
        </div>
      </section>

      <section
        className=" w-screen h-full cursor-pointer "
        onClick={onClose}
      ></section>
    </main>
  );
};

export default SidebarScaffold;
