import { createContext, useState } from 'react';
import { months } from '../data/months';
import { ICalendarContext, MonthData } from '../types';

export const CalendarContext = createContext<ICalendarContext | null>(null);

function CalendarProvider({ children }: { children: React.ReactNode }) {
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(months[today.getMonth()]);
  const [selectedDate, setSelectedDate] = useState(today.getDate());

  const getNextMonth = (shortName: string) => {
    const index = months.findIndex(month => month.shortName === shortName);
    if (index + 1 < months.length) {
      return months[index + 1];
    }
    return null;
  };

  const getPreviousMonth = (shortName: string) => {
    const index = months.findIndex(month => month.shortName === shortName);
    if (index - 1 >= 0) {
      return months[index - 1];
    }
    return null;
  };

  const nextDate = () => {
    const nextDate = selectedDate + 1;
    if (nextDate > selectedMonth.numberOfDays) {
      const nextMonth = getNextMonth(selectedMonth.shortName);
      if (nextMonth) {
        setSelectedMonth(nextMonth);
        setSelectedDate(1);
      }
    } else {
      setSelectedDate(nextDate);
    }
  };

  const previousDate = () => {
    const previousDate = selectedDate - 1;
    if (previousDate <= 0) {
      const previousMonth = getPreviousMonth(selectedMonth.shortName);
      if (previousMonth) {
        setSelectedMonth(previousMonth);
        setSelectedDate(previousMonth.numberOfDays);
      }
    } else {
      setSelectedDate(previousDate);
    }
  };

  const setMonthAndDate = (month: MonthData, date: number) => {
    setSelectedMonth(month);
    setSelectedDate(date);
  };

  return (
    <CalendarContext.Provider
      value={{
        today,
        selectedMonth,
        selectedDate,
        nextDate,
        previousDate,
        setMonthAndDate,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}

export default CalendarProvider;
