// import React from 'react'

import { useEffect, useRef } from 'react';

interface RangeSliderProps {
  /**
   * width of the view
   */
  width: string;
  /**
   * height of the view
   */
  height: string;
  /**
   * index of the selected value. Or it can be the percentage of the slider
   */
  value?: number;
  /**
   * onChange event handler. It is passed the percentage of the slider or the index of the selected value
   */
  onChange?: (value: number) => void;
  /**
   * possible number of values. It is an optional props. One of the values will be reported as a change
   * but if values is undefined or null, the slided percentage will be reported as change
   */
  valuesCount?: number;
  /**
   * An optional component that can be used as the slider base.
   */
  SliderBaseComponent?: React.ReactNode;
  /**
   * Optional additional class name to customize the parent component
   */
  sliderBaseClassName?: string;
  /**
   * Optional additional class name to customize the slidable view
   */
  sliderClassName?: string;
}

function RangeSlider({
  width,
  height,
  value = 0,
  valuesCount = 100,
  onChange,
  sliderBaseClassName = '',
  sliderClassName = '',
  SliderBaseComponent,
}: RangeSliderProps) {
  const isDragging = useRef(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const sliderBaseRef = useRef<HTMLDivElement>(null);
  const draggableItemPositionLeft = useRef(0);

  useEffect(() => {
    const sliderBaseWidth =
      sliderBaseRef.current?.getBoundingClientRect().width || 0;
    const sliderWidth = sliderRef.current?.getBoundingClientRect().width || 0;
    const singleValueWidth = sliderBaseWidth / (valuesCount - 1);
    const sliderBaseOffsetLeft =
      sliderBaseRef.current?.getBoundingClientRect().left || 0;

    const minimumPositionLeft = -1;
    const maximumPositionLeft = sliderBaseWidth - sliderWidth;

    const setPositionLeft = (positionLeft: number) => {
      if (sliderRef.current) {
        const adjustedPositionLeft = Math.min(
          Math.max(positionLeft - sliderWidth / 2, minimumPositionLeft),
          maximumPositionLeft
        );

        draggableItemPositionLeft.current = adjustedPositionLeft;
        sliderRef.current.style.left = adjustedPositionLeft + 'px';
      }
    };

    setPositionLeft(value * singleValueWidth);

    const startTouchOrMouse = () => {
      isDragging.current = true;
    };

    const endTouchOrMouse = () => {
      isDragging.current = false;
      const closestValueIndex = Math.round(
        draggableItemPositionLeft.current / singleValueWidth
      );
      setPositionLeft(closestValueIndex * singleValueWidth);
      if (onChange) onChange(closestValueIndex);
    };

    const moveTouchOrMouse = (e: TouchEvent | MouseEvent) => {
      const at =
        e instanceof TouchEvent
          ? (e as TouchEvent).touches[0].clientX
          : (e as MouseEvent).clientX;

      if (isDragging.current && sliderRef.current) {
        setPositionLeft(at - sliderBaseOffsetLeft);
      }
    };

    const option = { passive: true };
    sliderRef.current?.addEventListener(
      'touchstart',
      startTouchOrMouse,
      option
    );
    sliderRef.current?.addEventListener('mousedown', startTouchOrMouse, option);

    document.addEventListener('touchmove', moveTouchOrMouse, option);
    document.addEventListener('mousemove', moveTouchOrMouse, option);

    document.addEventListener('touchend', endTouchOrMouse, option);
    document.addEventListener('mouseup', endTouchOrMouse, option);

    const sliderRefCopy = sliderRef.current;

    return () => {
      sliderRefCopy?.removeEventListener('touchstart', startTouchOrMouse);
      sliderRefCopy?.removeEventListener('mousedown', startTouchOrMouse);

      document.removeEventListener('touchmove', moveTouchOrMouse);
      document.removeEventListener('mousemove', moveTouchOrMouse);

      document.removeEventListener('touchend', endTouchOrMouse);
      document.removeEventListener('mouseup', endTouchOrMouse);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`relative ${sliderBaseClassName} `}
      ref={sliderBaseRef}
      style={{
        width,
        height,
      }}
    >
      {SliderBaseComponent}
      <div
        className={` absolute ${sliderClassName} `}
        style={{
          width: height,
          height,
          top: '-1px',
          left: '-1px',
        }}
        ref={sliderRef}
      ></div>
    </div>
  );
}

export default RangeSlider;
