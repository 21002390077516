/**
 * Start of Calendar Related Types
 */

export type MonthData = {
    name: string;
    shortName: string;
    numberOfDays: number;
};

export interface ICalendarState {
    today: Date;
    selectedMonth: MonthData;
    selectedDate: number;
}

export interface ICalendarContext extends ICalendarState {
    previousDate: () => void;
    nextDate: () => void;
    setMonthAndDate: (month: MonthData, date: number) => void;
}

/**
 * Start of Setting Related Types
 */

export enum BibleTranslationType {
    KJV = "KJV",
    NKJV = "NKJV",
}
export interface ISettingsState {
    fontSize: number; // rem
    bibleTranslation: BibleTranslationType; //
}

export interface ISettingsContext extends ISettingsState {
    setFontSize: (fs: number) => void;
    setBibleTranslation: (bibleTranslation: BibleTranslationType) => void;
}

/**
 * Start of Bible Related Types
 */
export interface IVerse {
    index: string;
    content: string;
    LCComents: string[];
    LFTComments: string[];
}

export interface IChapter {
    index: string;
    verses: IVerse[];
}
export interface IBook {
    title: string;
    chapterStart: string;
    chapterEnd: string;
    chapters: IChapter[];
}

export interface TestamentDataAPIResponse {
    book: string;
    chapter_start: string;
    chapter_end: string;
    LCComments: {
        [x: string]: {
            [x: string]: string[];
        };
    };
    LFTComments: {
        [x: string]: {
            [x: string]: string[];
        };
    };
    verse: {
        [x: string]: {
            [x: string]: string;
        };
    };
}
export interface GetDayReadingAPIResponse {
    day: number;
    old_testament: TestamentDataAPIResponse;
    new_testament: TestamentDataAPIResponse;
}

export interface GetDayReadingResponse {
    day: number;
    oldTestament: IBook;
    newTestament: IBook;
}

export interface IBibleContentState {
    loading: boolean;
    oldTestament: IBook | undefined;
    newTestament: IBook | undefined;
    error: string | undefined;
}

export interface IBibleContentContext extends IBibleContentState {}

export interface ScreenSizeContextTypes {
    isDesktop: boolean;
    screenSize: number;
}

/**
 * Start of contact us page types
 */

export interface ContactUsFormData {
    name: string;
    email: string;
    subject: string;
    body: string;
}
