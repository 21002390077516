import { getMMDD } from './data/months';
import { MonthData } from './types';

export enum GTMEvents {
  previousDate = 'Click Previous',
  nextDate = 'Click Next',
  menuItem = 'Click Menu Item',
  buttonClick = 'Click Button',
}

export interface DateNavigationEvent {
  event: GTMEvents.previousDate | GTMEvents.nextDate;
  date_from: string;
}

const notifyClickPreviousDate = (fromMonth: MonthData, fromDate: number) => {
  notifyEvent({
    event: GTMEvents.previousDate,
    date_from: getMMDD(fromMonth, fromDate),
  });
};

const notifyClickNextDate = (fromMonth: MonthData, fromDate: number) => {
  notifyEvent({
    event: GTMEvents.nextDate,
    date_from: getMMDD(fromMonth, fromDate),
  });
};

const notifyEvent = (event: DateNavigationEvent | Object) => {
  // @ts-expect-error
  if (dataLayer) dataLayer.push(event);
};

const GTM = {
  notifyEvent,
  notifyClickNextDate,
  notifyClickPreviousDate,
};

export default GTM;
