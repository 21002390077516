import { useContext } from "react";
import { ScreenSizeContext } from "../contexts/ScreenSize";

//This is a custom hook that allows you to use the ScreenSizeContext
//without having to import the context and useContext from react
const useScreenSize = () => {
    const context = useContext(ScreenSizeContext);

    if (!context) throw new Error("ScreenSize context must be use inside ScreenSizeProvider");

    return context;
};

export default useScreenSize;
