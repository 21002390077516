interface SlideScaffoldProps {
  children: React.ReactNode;
  className?: string;
}

const SlideScaffold = ({ children, className }: SlideScaffoldProps) => {
  return (
    <div
      className={
        'shrink-0 w-full px-4 pt-20 overflow-x-hidden overflow-y-auto ' +
        (className || '')
      }
    >
      {children}
    </div>
  );
};

export default SlideScaffold;
