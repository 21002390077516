import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';
import Reading from '../../pages/Reading';
import { EXTERNAL_LINKS, PATH } from '../../routes/paths';
import Sidebar from '../Sidebar';
import Footer from './Footer';
import Topbar from './Topbar';

import LCIcon from '../../assets/icons/LCIcon';
import gospelTruthTvLogo from '../../assets/gospel-truth-tv-logo.svg';

const AppLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const scrollToTopRef = useRef<HTMLDivElement>(null);

  const handleCloseSideBar = () => {
    navigate(PATH.root);
  };

  const scrollToTop = () => {
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const isSideBarOpen = pathname !== PATH.root;

  return (
    <div
      ref={scrollToTopRef}
      className={isSideBarOpen ? 'overflow-hidden h-screen' : 'overflow-auto'}
    >
      <Topbar
        isSideBarOpen={isSideBarOpen}
        openSideBar={() => {
          navigate(PATH.menu);
        }}
      />
      <Sidebar isOpen={isSideBarOpen} onClose={handleCloseSideBar} />
      <Reading />

      <div className="flex flex-col items-center my-4">
        <Button
          variant={'outlined'}
          onClick={scrollToTop}
          className="w-44 px-4 mt-20"
        >
          Scroll to Top
        </Button>

        <div className=" mt-14 px-2 py-1 text-center text-[2.5em]  text-primary-light  font-avenir-demibold leading-extra-loose">
          Our prayer ministers would love to <br />
          pray with you
        </div>

        <div className="my-2 text-2xl text-black font-klinic-slab-book-italic">
          Prayer ministers available 24/7
        </div>

        <Button
          variant={'outlined'}
          to={EXTERNAL_LINKS.awmiPhone}
          className="w-44 px-4 my-16"
        >
          Receive Prayer
        </Button>

        <LCIcon className="block w-ful max-w-[344px] mb-20" />

        <a
          href={EXTERNAL_LINKS.gospelTruthTv}
          className="block"
          target="_blank"
          rel="noreferrer"
        >
          <div className="my-2 text-center font-klinic-slab-book text-2xl text-blue-light ">
            Click to check out Andrew’s
          </div>
          <img
            src={gospelTruthTvLogo}
            alt="gospel truth tv logo"
            className="block w-ful max-w-[344px]"
          />
        </a>

        <Button
          variant={'outlined'}
          to={EXTERNAL_LINKS.donate}
          className="w-44 px-4 my-20 "
          target="_blank"
          rel="noreferrer"
        >
          Donate
        </Button>
      </div>

      <Footer />
    </div>
  );
};

export default AppLayout;
