import { IconProps } from './IconProps';

interface LCIconProps extends IconProps {
  fillColor?: string;
}

const LCIcon = ({ className, fillColor, ...other }: LCIconProps) => {
  fillColor = fillColor || '#5695be';

  return (
    <svg
      id="Group_74064"
      data-name="Group 74064"
      xmlns="http://www.w3.org/2000/svg"
      width="344.055"
      height="124.583"
      viewBox="0 0 344.055 124.583"
      className={className}
      {...other}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_99"
            data-name="Rectangle 99"
            width="344.055"
            height="124.583"
            fill={fillColor}
          />
        </clipPath>
      </defs>
      <path
        id="Path_46"
        data-name="Path 46"
        d="M154.072,49.737H133.8V.422h7.438V42.337h12.831Z"
        transform="translate(41.258 0.13)"
        fill={fillColor}
      />
      <rect
        id="Rectangle_96"
        data-name="Rectangle 96"
        width="7.439"
        height="49.316"
        transform="translate(200.445 0.551)"
        fill={fillColor}
      />
      <path
        id="Path_47"
        data-name="Path 47"
        d="M172.46,49.737,163.644.422h7.8l6.191,38.441L183.866.422h7.8l-8.973,49.316Z"
        transform="translate(50.459 0.13)"
        fill={fillColor}
      />
      <path
        id="Path_48"
        data-name="Path 48"
        d="M208.937.391l9.872,33.171V.391h7.438V49.747h-7.989l-10.234-31.6v31.6h-7.438V.391Z"
        transform="translate(61.85 0.12)"
        fill={fillColor}
      />
      <g id="Group_64" data-name="Group 64" transform="translate(0 0)">
        <g id="Group_63" data-name="Group 63" clipPath="url(#clip-path)">
          <path
            id="Path_49"
            data-name="Path 49"
            d="M237,50.5a11.791,11.791,0,0,1-11.808-11.769V11.808a11.355,11.355,0,0,1,3.463-8.364,11.809,11.809,0,0,1,16.689.02,11.439,11.439,0,0,1,3.424,8.345v5.629h-7.714V11.651a4.3,4.3,0,0,0-4.29-4.29,4.066,4.066,0,0,0-3.012,1.259,4.164,4.164,0,0,0-1.239,3.031v27a4.233,4.233,0,0,0,4.251,4.251,4.171,4.171,0,0,0,3.03-1.236,4.055,4.055,0,0,0,1.26-3V28.968h-4.133V21.49h11.847V38.728A11.757,11.757,0,0,1,237,50.5"
            transform="translate(69.437 0)"
            fill={fillColor}
          />
          <path
            id="Path_50"
            data-name="Path 50"
            d="M46.767,107.123A11.757,11.757,0,0,1,34.959,95.354V68.434A11.791,11.791,0,0,1,46.767,56.626a11.3,11.3,0,0,1,8.345,3.463,11.444,11.444,0,0,1,3.424,8.345v5.629H50.821V68.277a4.3,4.3,0,0,0-4.291-4.29,4.066,4.066,0,0,0-3.011,1.259,4.165,4.165,0,0,0-1.24,3.031v27a4.235,4.235,0,0,0,4.251,4.251,4.167,4.167,0,0,0,3.031-1.24,4.072,4.072,0,0,0,1.26-3.01V88.467h7.714v6.887a11.757,11.757,0,0,1-11.769,11.769"
            transform="translate(10.779 17.46)"
            fill={fillColor}
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M69.318,56.566A11.793,11.793,0,0,1,81.126,68.374V95.3a11.8,11.8,0,0,1-20.151,8.343A11.436,11.436,0,0,1,57.551,95.3V68.374A11.793,11.793,0,0,1,69.318,56.566m4.055,11.651a4.233,4.233,0,0,0-4.251-4.251,4.169,4.169,0,0,0-3.031,1.239,4.067,4.067,0,0,0-1.259,3.012v27a4.066,4.066,0,0,0,1.259,3.01,4.17,4.17,0,0,0,3.031,1.24,4.235,4.235,0,0,0,4.251-4.251Z"
            transform="translate(17.746 17.442)"
            fill={fillColor}
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M88,106.372H80.563V57.018H91.237L98.17,92.471l6.654-35.454h10.256v49.355h-7.439V75.358l-6.454,31.014H95.2L88,75.437Z"
            transform="translate(24.841 17.581)"
            fill={fillColor}
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M119.468,106.372h-7.439V57.018H122.7l6.933,35.454,6.654-35.454h10.256v49.355h-7.439V75.358l-6.454,31.014h-5.983l-7.2-30.936Z"
            transform="translate(34.544 17.581)"
            fill={fillColor}
          />
          <path
            id="Path_54"
            data-name="Path 54"
            d="M143.5,106.372V57.018H164.67v7.477H150.935v13.46h10.036v7.479H150.935v13.46H164.67v7.479Z"
            transform="translate(44.246 17.581)"
            fill={fillColor}
          />
          <path
            id="Path_55"
            data-name="Path 55"
            d="M171.942,57.017l9.871,33.169V57.017h7.438v49.355h-7.989l-10.234-31.6v31.6h-7.438V57.017Z"
            transform="translate(50.443 17.581)"
            fill={fillColor}
          />
          <path
            id="Path_56"
            data-name="Path 56"
            d="M208.888,95.028l-1.807,11.336h-7.745l8.384-49.316h10.233l8.265,49.316H218.4l-1.74-11.336ZM212.8,68.343l-2.756,19.443h5.511Z"
            transform="translate(61.465 17.59)"
            fill={fillColor}
          />
          <path
            id="Path_57"
            data-name="Path 57"
            d="M235.064,57.047q6.217,0,9.131,3.424,2.557,2.991,2.558,8.345v7.163a11.523,11.523,0,0,1-3.975,8.855l5.509,21.529h-8.045l-4.51-18.577h-4.959v18.577h-7.439V57.047Zm4.447,11.886q0-4.29-4.251-4.29h-4.486V80.426h4.486a4.063,4.063,0,0,0,3.012-1.26,4.164,4.164,0,0,0,1.239-3.03Z"
            transform="translate(68.865 17.59)"
            fill={fillColor}
          />
          <path
            id="Path_58"
            data-name="Path 58"
            d="M251.99,85.71l-9.958-28.692h8.186l5.511,17.71,5.47-17.71h8.226l-10,28.692v20.663H251.99Z"
            transform="translate(74.63 17.581)"
            fill={fillColor}
          />
          <path
            id="Path_59"
            data-name="Path 59"
            d="M18.148,40.982a29.663,29.663,0,0,1-7.85,4.649,84.845,84.845,0,0,1-8.445,2.826A.957.957,0,0,0,0,48.8l22.038.249a1.5,1.5,0,1,1,0,.358L0,49.154a.957.957,0,0,0,1.84.381,84.934,84.934,0,0,1,8.38,3.016,29.668,29.668,0,0,1,7.744,4.824c6.763-1.552,13.193-3.116,19.29-2.176L37.3,50.99l.016-1.416,0-.179v-.179l.017-1.414.047-4.209c-6.117.8-12.51-.907-19.237-2.61"
            transform="translate(0 12.637)"
            fill={fillColor}
          />
          <rect
            id="Rectangle_97"
            data-name="Rectangle 97"
            width="14.917"
            height="6.621"
            transform="matrix(0.011, -1, 1, 0.011, 38.951, 69.51)"
            fill={fillColor}
          />
          <path
            id="Path_60"
            data-name="Path 60"
            d="M203.933,45.426l-6.662-.009,6.658.086c0-.026,0-.051,0-.077"
            transform="translate(60.828 14.004)"
            fill={fillColor}
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M204.018,45.221l-6.684-.009a.268.268,0,0,0,0,.535l6.652.009c.011-.178.021-.356.037-.535"
            transform="translate(60.767 13.941)"
            fill={fillColor}
          />
          <path
            id="Path_62"
            data-name="Path 62"
            d="M33.877,48.859,247.81,46.583s-.3-1.837-3.569-1.911c0,0-.014-.365-.054-1.01-.458.034-.916.055-1.375.055a14.432,14.432,0,0,1-3.213-.364c-.1.022-6.4,1.454-9.771-1.717,0,0-2.072,2.507-8.381,2.5a12.928,12.928,0,0,1-7.2-2.135s-5.241,2.492-19.169,2.474l-80.793-.945-37.942-.321s-20.864-.089-42.977,2.04a18.613,18.613,0,0,1,.509,3.612"
            transform="translate(10.289 12.838)"
            fill={fillColor}
          />
          <path
            id="Path_63"
            data-name="Path 63"
            d="M239,43.26c-2.99.513-6.607.382-8.962-1.747a.268.268,0,0,0-.377-.007,5.493,5.493,0,0,1-1.847,1.248,16.11,16.11,0,0,1-6.373,1.176,11.855,11.855,0,0,1-4-.632,12.464,12.464,0,0,1-1.722-.7,9.925,9.925,0,0,0-1.386-.794.515.515,0,0,0-.33.081,24.254,24.254,0,0,1-5.029,1.328A76.526,76.526,0,0,1,195.1,44.267c-6.094,0-12.2-.143-18.289-.213l-35.951-.421-25.241-.294c-6.667-.078-13.335-.116-20-.173-7.155-.06-14.313-.205-21.469-.156-13.619.093-27.262.729-40.822,2.044.044.173.086.347.126.519.332-.031.663-.068,1-.1,8.529-.8,17.084-1.3,25.645-1.612q10.478-.383,20.958-.284l31.635.267c4.736.041,9.472.106,14.209.161l34.779.407,29.46.344c2.826.033,5.643.072,8.47-.041a62.285,62.285,0,0,0,11.291-1.35,17.309,17.309,0,0,0,3.5-1.07c-.183.085,1.179.822,1.316.888a13.406,13.406,0,0,0,1.345.548,13.265,13.265,0,0,0,2.639.625,17.043,17.043,0,0,0,3.822.014c2.019-.177,4.843-.709,6.352-2.3,2.456,2.048,5.95,2.194,8.995,1.749.276-.039.551-.088.823-.148a.42.42,0,0,0,.218-.2c-.3-.064-.607-.136-.905-.218"
            transform="translate(10.274 12.775)"
            fill={fillColor}
          />
          <path
            id="Path_64"
            data-name="Path 64"
            d="M194.875,43.946c-.01-.258-.035-.517-.048-.775-.178.016-.356.025-.534.035.017.331.046.661.059.99a.273.273,0,0,0,.267.27c1.085.047,3.056.332,3.31,1.71.063.34.581.205.519-.133-.284-1.541-2.227-2-3.573-2.1"
            transform="translate(59.91 13.312)"
            fill={fillColor}
          />
          <path
            id="Path_65"
            data-name="Path 65"
            d="M204.146,49.788l-6.883.069,6.9.01-.012-.08"
            transform="translate(60.825 15.352)"
            fill={fillColor}
          />
          <path
            id="Path_66"
            data-name="Path 66"
            d="M204.18,49.646l-6.853-.009a.268.268,0,0,0,0,.535l6.937.01c-.026-.179-.057-.357-.08-.536"
            transform="translate(60.765 15.305)"
            fill={fillColor}
          />
          <path
            id="Path_67"
            data-name="Path 67"
            d="M33.845,47.051c.017.364.027.727.022,1.09a15.085,15.085,0,0,1-.42,3.319C55.51,53.637,76.3,53.606,76.3,53.606l37.943-.216,80.8-.722c13.927.02,19.161,2.526,19.161,2.526a12.925,12.925,0,0,1,7.205-2.116c6.309.008,8.373,2.52,8.373,2.52,3.336-3.118,9.5-1.752,9.76-1.693a19.667,19.667,0,0,1,4.641-.88c.014-.267.021-.421.021-.421,3.272-.065,3.573-1.9,3.573-1.9Z"
            transform="translate(10.313 14.508)"
            fill={fillColor}
          />
          <path
            id="Path_68"
            data-name="Path 68"
            d="M194.846,52.209c0-.05.006-.1.009-.15,1.344-.094,3.291-.546,3.577-2.084.063-.336-.453-.482-.517-.141-.256,1.379-2.222,1.66-3.313,1.7a.272.272,0,0,0-.27.266c-.007.148-.02.294-.027.442.181-.014.36-.027.54-.037"
            transform="translate(59.914 15.306)"
            fill={fillColor}
          />
          <path
            id="Path_69"
            data-name="Path 69"
            d="M239.819,52.8c-.106-.192-.485-.195-.667-.228-2.922-.519-6.54-.487-9,1.4-.113.086-.222.177-.331.268-1.592-1.688-4.561-2.157-6.692-2.347a16.208,16.208,0,0,0-3.76.076,13.518,13.518,0,0,0-3.99,1.261c-.116.056-1.227.654-1.035.744a18.383,18.383,0,0,0-3.65-1.113,62.863,62.863,0,0,0-11.2-1.349c-6.734-.3-13.52-.014-20.256.046l-35.04.313-27.249.243c-5.581.05-11.164.072-16.746.1-8.074.046-16.149.154-24.221.136-12.178-.029-24.377-.6-36.512-1.6-1.981-.164-3.963-.348-5.945-.54-.044.2-.092.4-.144.6,8.517.833,17.077,1.314,25.579,1.666q10.535.434,21.075.389L111.62,52.7q6.647-.039,13.3-.111l33.511-.3,30.733-.275,5.855-.052a79.044,79.044,0,0,1,12,.775,33.262,33.262,0,0,1,6.525,1.5c.747.27.7.255,1.584-.254a12.515,12.515,0,0,1,5.907-1.613,16.657,16.657,0,0,1,6.033.9,6.075,6.075,0,0,1,2.541,1.543.271.271,0,0,0,.379.008c2.389-2.148,6.011-2.236,9.024-1.722l.041.008q.391-.137.785-.256c-.007-.012-.01-.029-.017-.041"
            transform="translate(10.293 15.485)"
            fill={fillColor}
          />
          <path
            id="Path_70"
            data-name="Path 70"
            d="M254.328,47.072c.007-.4.029-.807.06-1.214h-6.383l-1.205-.731-2.2-.544-4.162-.255-3.247.536-14.357-.02-22.349.76L73.659,44.1,34.822,46.234l-1.187.092a17.991,17.991,0,0,1,.174,2.727,15.477,15.477,0,0,1-.152,1.913l19.636,1.923,24.371.033L131.5,53l26.514.037,53.3-1.1,19.013,1.2,11.516.016,3.495-.969,2.252-.2.4-.327.864-.071,5.762.008a30.239,30.239,0,0,1-.28-4.518"
            transform="translate(10.371 13.597)"
            fill={fillColor}
          />
          <path
            id="Path_71"
            data-name="Path 71"
            d="M248.031,45.647c.173,0-.347-.245-.484-.328a2.9,2.9,0,0,0-.67-.394l-2-.493a20.919,20.919,0,0,0-3.658-.267c-1.871-.115-3.576.489-5.38.487l-9.716-.013c-4.213-.007-8.417.17-12.627.313-4.352.148-8.746.493-13.1.441l-18.757-.222-38.944-.462-41.268-.491-25.725-.3c-1.255-.014-2.473,0-3.726.069l-17.51.963c-6.957.382-13.906.773-20.854,1.234.024.153.048.306.068.461.606-.039,1.212-.082,1.817-.115L70.291,44.61c4.435-.245,8.851-.12,13.294-.068l34.513.41,42.464.505,32.549.386c5.738.068,11.4-.05,17.148-.245,4.515-.154,9.043-.433,13.561-.426l9.522.013,3.523.005a16.912,16.912,0,0,0,2.737-.4,18.339,18.339,0,0,1,7.11.642c.408.1.839.746,1.3.746h6.372c.01-.179.018-.357.035-.536l-6.387,0"
            transform="translate(10.364 13.539)"
            fill={fillColor}
          />
          <path
            id="Path_72"
            data-name="Path 72"
            d="M254.569,49.731a53.212,53.212,0,0,0-5.763.031,1.91,1.91,0,0,0-1.342.416c.122-.1-.4.022-.587.039l-1.332.118a20,20,0,0,0-3.432.9,9.918,9.918,0,0,1-2.235.082l-5.079-.007c-1.743,0-3.493.055-5.232-.055l-17.4-1.1c-2.551-.161-5.14.035-7.694.088l-39,.806c-8.393.174-16.777.141-25.171.131l-23.592-.033L79.821,51.1l-26.047-.035c-.824,0-1.642-.115-2.462-.2L43.6,50.113l-9.9-.969c-.022.178-.05.353-.079.529l5.307.519,14.237,1.395c1.227.119,2.509.016,3.741.017l9.981.014,24.229.033,37.552.052,26.7.037c8.258.012,16.522-.284,24.777-.454l31.126-.644c1.621-.034,3.29.2,4.908.306l10.2.645c4.611.292,9.2.256,13.821.263a7.64,7.64,0,0,0,2.046-.1,22.011,22.011,0,0,1,3.611-.913,11.6,11.6,0,0,0,1.8-.161c.132-.034.526-.334.381-.322.272-.022.551-.067.823-.067l3.342.005,2.454,0c-.027-.19-.06-.379-.084-.57"
            transform="translate(10.366 15.153)"
            fill={fillColor}
          />
          <path
            id="Path_73"
            data-name="Path 73"
            d="M234.575,46.047c16.969,1.121,18.353-4.066,18.353-4.066s8.742,2.869,8.748,7.179Z"
            transform="translate(72.33 12.945)"
            fill={fillColor}
          />
          <path
            id="Path_74"
            data-name="Path 74"
            d="M234.634,46.459a51.792,51.792,0,0,0,10.288-.2,18.138,18.138,0,0,0,5.473-1.5c.949-.458,2.482-1.267,2.854-2.615l-.33.234a24.337,24.337,0,0,1,3.962,1.744c1.635.89,4.516,2.485,4.588,5.11.012.428.548.434.536,0-.075-2.659-2.49-4.272-4.162-5.291a23.26,23.26,0,0,0-4.783-2.211c-.148-.048-.281.061-.33.234.029-.1-.026.063-.065.141a2.614,2.614,0,0,1-.351.509,6.479,6.479,0,0,1-2.363,1.641,18.815,18.815,0,0,1-5.447,1.387,51.482,51.482,0,0,1-9.867.143c-.344-.022-.347.644,0,.666"
            transform="translate(72.27 12.866)"
            fill={fillColor}
          />
          <path
            id="Path_75"
            data-name="Path 75"
            d="M234.452,44.928c-.247.3.126.78.372.48s-.124-.78-.372-.48"
            transform="translate(72.268 13.824)"
            fill={fillColor}
          />
          <path
            id="Path_76"
            data-name="Path 76"
            d="M215.682,44.927l24.728.4s-1.011-1.813-2.166-.539l-6.181-.01-7.8-.013-8.612-.014c.01.06.02.12.029.181"
            transform="translate(66.496 13.669)"
            fill={fillColor}
          />
          <path
            id="Path_77"
            data-name="Path 77"
            d="M240.656,45.232c-.561-.976-1.477-1.494-2.359-.837-.566.423-2.036.123-2.775.122l-5.468-.009-12.58-.021-1.862,0c.037.222.072.445.1.667l10.424.017,6.644.012,4.5.008a3.6,3.6,0,0,0,1.034-.009,1.982,1.982,0,0,0,.4-.326.759.759,0,0,1,.909.081,2.426,2.426,0,0,1,.552.623c.2.347.669.026.468-.323"
            transform="translate(66.484 13.599)"
            fill={fillColor}
          />
          <path
            id="Path_78"
            data-name="Path 78"
            d="M234.569,50.486c16.972-1.062,18.344,4.129,18.344,4.129s8.749-2.84,8.763-7.149Z"
            transform="translate(72.329 14.636)"
            fill={fillColor}
          />
          <path
            id="Path_79"
            data-name="Path 79"
            d="M234.629,50.9c5-.3,10.519-.563,15.31,1.58a6.61,6.61,0,0,1,2.321,1.6,4.5,4.5,0,0,1,.455.695c.046.171.177.289.326.241a24.63,24.63,0,0,0,4.613-2.089c1.722-.994,4.271-2.661,4.352-5.38.013-.43-.523-.434-.535,0-.077,2.571-2.826,4.1-4.422,5a23.1,23.1,0,0,1-4.141,1.83l.326.239c-.369-1.359-1.893-2.154-2.844-2.628a17.9,17.9,0,0,0-5.47-1.518,50.081,50.081,0,0,0-10.287-.233c-.343.021-.349.687,0,.666"
            transform="translate(72.268 14.56)"
            fill={fillColor}
          />
          <path
            id="Path_80"
            data-name="Path 80"
            d="M234.446,49.613c-.247.3.126.78.372.48s-.124-.78-.372-.48"
            transform="translate(72.266 15.269)"
            fill={fillColor}
          />
          <path
            id="Path_81"
            data-name="Path 81"
            d="M216.1,50.079c0,.063,0,.126,0,.19l8.023.013,7.8.013,6.182.01c1.151,1.28,2.167-.532,2.167-.532Z"
            transform="translate(66.632 15.347)"
            fill={fillColor}
          />
          <path
            id="Path_82"
            data-name="Path 82"
            d="M240.035,49.678c-.33.569-.894,1.01-1.46.712-.478-.254-.453-.343-.967-.344l-3.981-.007-5.43-.009-12.1-.021c0,.222.005.445.005.666l9.884.017,7.327.013,4.626.008c.2,0,.154.092.366.25a1.174,1.174,0,0,0,.984.157,2.209,2.209,0,0,0,1.209-1.1c.2-.349-.26-.686-.46-.34"
            transform="translate(66.632 15.273)"
            fill={fillColor}
          />
          <path
            id="Path_83"
            data-name="Path 83"
            d="M248.6,45.456l-6.822-.234-1.37,0-.691-.5h-1.162l-19.978-.054h-2.921a47.493,47.493,0,0,1,.574,7.137l11.177.02,12.62.021.374-.495,5.08-.139,9.233.164,8.553-.625,4.24-2.42Z"
            transform="translate(66.497 13.772)"
            fill={fillColor}
          />
          <path
            id="Path_84"
            data-name="Path 84"
            d="M267.679,48.16l-.016-.014-.013-.013a.253.253,0,0,0-.128-.06l-15.993-2.43a41.694,41.694,0,0,0-4.875-.51l-4.569-.157c-.393-.013-.786-.01-1.18-.012-.522,0-.764-.5-1.32-.5q-11.983-.016-23.968-.05c.037.221.071.442.1.663,1.851,0,3.7,0,5.555,0l17.143.047h.823c.522,0,.767.5,1.319.5,1.064,0,2.125.034,3.188.069a50.628,50.628,0,0,1,5.907.327l16.9,2.566-2.776,1.586c-.189.106-.376.213-.563.321l-.1.007-1.679.123c-2.35.171-4.736.525-7.091.483l-8.55-.15c-.946-.017-1.893.038-2.838.064l-2.164.059-.377.009c-.178.005-.639.5-.378.5l-23.8-.041c0,.222.007.445.005.666l6.908.012,14.582.025a8.375,8.375,0,0,0,2.372-.007c.151-.047.563-.492.318-.484l.489-.014,2.46-.067c2.354-.064,4.7-.012,7.056.03,3.031.054,5.971-.047,9-.268l2.818-.205a2.923,2.923,0,0,0,1.175-.124l4.151-2.369a.2.2,0,0,0,.166-.126.378.378,0,0,0-.062-.455"
            transform="translate(66.485 13.696)"
            fill={fillColor}
          />
          <rect
            id="Rectangle_98"
            data-name="Rectangle 98"
            width="29.213"
            height="7.881"
            transform="translate(256.282 58.057)"
            fill={fillColor}
          />
          <path
            id="Path_85"
            data-name="Path 85"
            d="M188.507,116.882l1.723-54.76L188.507,7.362c0-3.818,1.675-6.941,3.722-6.941h2.206c2.046,0,3.722,3.123,3.722,6.941l-1.206,55.564,1.206,53.956c0,3.818-1.676,6.941-3.722,6.941h-2.206c-2.047,0-3.722-3.123-3.722-6.941"
            transform="translate(58.126 0.13)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
};

export default LCIcon;
