import { useContext } from 'react';
import { BibleContentContext } from '../contexts/BibleContent';

const useBibleContent = () => {
  const context = useContext(BibleContentContext);

  if (!context)
    throw new Error(
      'BibleContent context must be use inside BibleContentProvider'
    );

  return context;
};

export default useBibleContent;
