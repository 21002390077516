import { Outlet, useMatch } from 'react-router-dom';
import { PATH } from '../../routes/paths';
import SidebarScaffold from './SidebarScaffold';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar = ({ isOpen, onClose }: SidebarProps) => {
  const match = useMatch(PATH.menu);

  return (
    <SidebarScaffold
      isOpen={isOpen}
      onClose={onClose}
      btnCloseTheme={!!match ? 'light' : 'dark'}
    >
      {isOpen && (
        <div className="w-full h-full bg-white">
          <Outlet />
        </div>
      )}
    </SidebarScaffold>
  );
};

export default Sidebar;
