interface DateCellProps {
  /**
   * size of the cell
   */
  size: 'small' | 'large';
  /**
   * name of the month
   */
  month: string;
  /**
   * date of the month
   */
  date: number;
  /**
   * click event handler
   */
  onClick: () => void;
}

const DateCell = ({ size, month, date, onClick }: DateCellProps) => {
  let rootClassNames =
    'border-white bg-primary-darker flex flex-col items-center text-white cursor-pointer ';

  let monthClassNames =
    ' bg-primary-lighter w-full text-center font-avenir-bold ';

  let dateClassNames =
    'flex-grow flex items-center justify-center font-avenir-bold py-0.5 ';

  if (size === 'large') {
    rootClassNames += ' w-[110px] border-4 w-24 h-18 ';
    dateClassNames += ' text-5xl ';
  } else {
    rootClassNames += ' w-[76px] border w-20 h-14 ';
    monthClassNames += ' text-xs ';
    dateClassNames += ' text-3xl ';
  }

  return (
    <div className={rootClassNames} onClick={onClick}>
      <div className={monthClassNames}>{month.toUpperCase()}</div>
      <div className={dateClassNames}>{date}</div>
    </div>
  );
};

export default DateCell;
