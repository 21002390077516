import Button from '../../components/ui/Button';
import { EXTERNAL_LINKS } from '../../routes/paths';

interface CallForPrayerProps {
  color: 'primary' | 'white';
}

const CallForPrayer = ({ color }: CallForPrayerProps) => {
  return (
    <Button
      className={` w-fit px-4 bg-transparent mx-auto mt-8 ${
        color === 'primary' ? 'border-primary ' : 'border-white '
      }`}
      variant="outlined"
      color={color}
      to={EXTERNAL_LINKS.awmiPhone}
    >
      Call for Prayer
    </Button>
  );
};

export default CallForPrayer;
