import React from 'react';

interface CardProps {
  /**
   * view/views inside the card
   */
  children: React.ReactNode;
  /**
   * different looks of the card
   */
  variant: 'filled' | 'outlined';

  /**
   * additional class name to the root element
   */
  className?: string;
  [x: string]: any;
}

const Card = ({
  children,
  variant = 'outlined',
  className = '',
  ...other
}: CardProps) => {
  let classNames = ' rounded-xl p-4 ';

  if (variant === 'outlined') {
    classNames += ' border border-primary-lighter/40 bg-white';
  } else {
    classNames += ' bg-blue-lighter ';
  }

  classNames += ` ${className}`;

  return (
    <div className={classNames} {...other}>
      {children}
    </div>
  );
};

export default Card;
