import { useEffect } from 'react';
import { RouteObject, useNavigate } from 'react-router-dom';

import MenuPanel from '../layouts/Sidebar/MenuPanel';
import Introduction from '../pages/Introduction';
import PageNotFound from '../pages/PageNotFound';
import AppLayout from '../layouts/AppLayout';
import Calendar from '../pages/Calendar';
import Settings from '../pages/Settings';
// import ContactUs from '../pages/ContactUs';
import { PATH } from './paths';

const AppLayoutWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const lastSeen = parseInt(
      localStorage.getItem('introductionSeenAt') || '0'
    );

    const shouldShowIt = Date.now() - lastSeen > 30 * 24 * 60 * 1000; // more than a month ago

    if (shouldShowIt) {
      localStorage.setItem('introductionSeenAt', Date.now().toString());
      navigate(PATH.instructions);
    }
  }, [navigate]);

  return <AppLayout />;
};

export const appRoutes: RouteObject[] = [
  {
    path: PATH.instructions,
    element: <Introduction />,
  },
  {
    path: PATH.root,
    element: <AppLayoutWrapper />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: PATH.menu,
        element: <MenuPanel />,
      },
      {
        path: PATH.calendar,
        element: <Calendar />,
      },
      {
        path: PATH.settings,
        element: <Settings />,
      },
      // {
      //   path: PATH.contactUs,
      //   element: <ContactUs />,
      // },
    ],
  },
];
