import { createContext, useCallback, useDeferredValue, useState } from 'react';
import { BibleTranslationType, ISettingsContext } from '../types';

export const SettingsContext = createContext<ISettingsContext | null>(null);

export const defaultBibleFontSize = 1.125; // 18px
export const minimumBibleFontSize = 0.75; // rem
export const maximumBibleFontSize = 1.5; // rem

function SettingsProvider({ children }: { children: React.ReactNode }) {
  const [fontSize, setFontSize] = useState(defaultBibleFontSize);
  const [bibleTranslation, setBibleTranslation] = useState(
    BibleTranslationType.KJV
  );

  const updateFontSize = useCallback((fontSize: number) => {
    setFontSize(fontSize);
  }, []);

  const updateBibleTranslation = useCallback(
    (bibleTranslation: BibleTranslationType) => {
      setBibleTranslation(bibleTranslation);
    },
    []
  );

  const deferredFontSize = useDeferredValue(fontSize);

  return (
    <SettingsContext.Provider
      value={{
        fontSize: deferredFontSize,
        bibleTranslation,
        setFontSize: updateFontSize,
        setBibleTranslation: updateBibleTranslation,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsProvider;
