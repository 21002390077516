import SlideScaffold from './SlideScaffold';

const Slide1 = () => {
  return (
    <SlideScaffold className="flex flex-col justify-center items-center">
      <div className="text-primary text-6xl font-bold font-avenir-bold">
        Welcome.
      </div>
      <div className="text-center text-primary text-xl font-klinic-slab-book">
        Thank you for joining us! We are so glad you are here as we dedicate
        this year to reading the Bible together.
      </div>
    </SlideScaffold>
  );
};

export default Slide1;
