import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';
import { months } from '../../data/months';
import useCalendar from '../../hooks/useCalendar';
import PageScaffold from '../../layouts/PageScaffold';
import { PATH } from '../../routes/paths';
import { MonthData } from '../../types';
import DaysList from './DaysList';
import MonthsList from './MonthsList';

const Calendar = () => {
  const navigate = useNavigate();
  const { today, selectedMonth, selectedDate, setMonthAndDate } = useCalendar();

  const [month, setMonth] = useState<MonthData>(selectedMonth);
  const [date, setDate] = useState<number>(selectedDate);

  const resetToToday = () => {
    setMonth(months[today.getMonth()]);
    setDate(today.getDate());
  };

  const viewSelectedDate = () => {
    setMonthAndDate(month, date);
    navigate(PATH.root);
  };

  return (
    <PageScaffold
      header="Calendar"
      className="overflow-hidden flex h-screen flex-col"
    >
      <MonthsList
        selectedMonth={month}
        onSelectMonth={(month: MonthData) => {
          setMonth(month);
          setDate(1);
        }}
      />

      <DaysList
        selectedMonth={month}
        selectedDate={date}
        onSelectDate={(month: MonthData, date: number) => {
          setDate(date);
          setMonth(month);
        }}
      />

      <div className="py-4 flex align-center gap-[10px] px-2 justify-center flex-shrink-0">
        <Button variant="outlined" className="flex-1" onClick={resetToToday}>
          Reset to Today
        </Button>
        <Button variant="filled" className="flex-1" onClick={viewSelectedDate}>
          View Selected Date
        </Button>
      </div>
    </PageScaffold>
  );
};

export default Calendar;
