import { createContext, useEffect, useRef, useState } from 'react';
import useCalendar from '../hooks/useCalendar';
import { getDaysReading } from '../service';
import { IBibleContentContext, IBook, MonthData } from '../types';

export const BibleContentContext =
  createContext<IBibleContentContext | null>(null);

function BibleContentProvider({ children }: { children: React.ReactNode }) {
  const { selectedMonth, selectedDate } = useCalendar();

  const [loading, setLoading] = useState(false);
  const [oldTestament, setOldTestament] = useState<IBook | undefined>();
  const [newTestament, setNewTestament] = useState<IBook | undefined>();
  const [error, setError] = useState('');

  const debounceTimeOutRef = useRef<NodeJS.Timeout>();
  const handleDebouncedFetchData = (month: MonthData, date: number) => {
    clearTimeout(debounceTimeOutRef.current);

    debounceTimeOutRef.current = setTimeout(async () => {
      setLoading(true);
      try {
        const data = await getDaysReading(month, date);
        if (data) {
          setOldTestament(data.oldTestament);
          setNewTestament(data.newTestament);
        }
      } catch (error) {
        setOldTestament(undefined);
        setNewTestament(undefined);
        setError('Something went wrong');
      } finally {
        setLoading(false);
      }
    }, 400);
  };

  useEffect(() => {
    handleDebouncedFetchData(selectedMonth, selectedDate);
  }, [selectedMonth, selectedDate]);

  return (
    <BibleContentContext.Provider
      value={{ loading, oldTestament, newTestament, error }}
    >
      {children}
    </BibleContentContext.Provider>
  );
}

export default BibleContentProvider;
