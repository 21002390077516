import DateCell from '../../components/calendar/DateCell';
import { MonthData } from '../../types';

export const DATE_CELL_HEIGHT = 90;
export const DATE_CELL_BOTTOM_BORDER_WIDTH = 1;

interface MonthDaysProps {
  month: MonthData;
  isSelectedMonth: boolean;
  selectedDate: number | undefined;
  onSelectDate: (date: number) => void;
}

const MonthDays = ({
  month,
  isSelectedMonth,
  selectedDate,
  onSelectDate,
}: MonthDaysProps) => {
  return (
    <div className="relative flex flex-col items-center border-dashed border-b border-primary-light ">
      <div
        className={'absolute text-xl text-white/[0.3] left-[14px] top-[14px] '}
      >
        {month.name.toUpperCase()}
      </div>

      {new Array(month.numberOfDays).fill(undefined).map((_, index) => {
        const isSelectedDate = isSelectedMonth && selectedDate === index + 1;
        return (
          <div
            key={`${month.shortName}-${index}`}
            className={` w-full shrink-0 flex items-center justify-center ${
              isSelectedDate ? ' bg-primary-lighter/[0.73] ' : ''
            }`}
            style={{ height: DATE_CELL_HEIGHT + 'px' }}
          >
            <DateCell
              date={index + 1}
              month={month.name}
              size={isSelectedDate ? 'large' : 'small'}
              onClick={() => {
                onSelectDate(index + 1);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MonthDays;
