interface MonthCellProps {
  /**
   * name of the month
   */
  month: string;
  /**
   * different looks of the cell. either muted or bold
   */
  variant: 'muted' | 'bold';
  /**
   * click event handler
   */
  onClick?: () => void;
}

const MonthCell = ({ month, variant, onClick }: MonthCellProps) => {
  let classNames =
    'flex items-center justify-center font-avenir-bold bg-primary-dark shadow-lg cursor-pointer ';

  if (variant === 'muted') {
    classNames +=
      ' w-10 h-10 text-sm border-[1.5px] border-white text-primary-light ';
  } else {
    classNames +=
      ' w-[60px] h-[60px] text-xl border-2 border-primary-light text-white ';
  }

  return (
    <div className={classNames} onClick={onClick}>
      {month.toUpperCase()}
    </div>
  );
};

export default MonthCell;
