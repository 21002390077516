import SlideScaffold from './SlideScaffold';

const Slide2 = () => {
  return (
    <SlideScaffold>
      <ul className="space-y-6 list-disc text-primary text-2xl ml-4 h-full">
        <li>
          There are two ways to navigate this plan. Use the Previous and Next
          buttons to navigate to the previous day or the next day. You can also
          click on the calendar to select a reading for a specific day and
          month.
        </li>
        <li>
          Each day features readings from both the Old and New Testaments. To
          access Andrew’s notes on each verse, click the “Andrew’s Notes”
          buttons. You can also expand all of the notes at once with the "Swipe
          to Open All" button at the top.
        </li>

        <li>
          This reading plan contains Andrew’s notes on thousands of scriptures,
          but not all scriptures have notes. Those that do not will be grayed
          out and cannot be expanded.
        </li>
        <li>
          For more options, settings, and other helpful information, click the
          menu button ({' '}
          <svg
            className="inline-block w-7 h-7 stroke-primary-darker fill-none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
          ) at the top of the page
        </li>
      </ul>
    </SlideScaffold>
  );
};

export default Slide2;
