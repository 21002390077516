import {
  EXTERNAL_LINKS,
  instructionSlide2QueryString,
  PATH,
} from '../../routes/paths';

export type MenuItem = {
  name: string;
  to: string;
  isExternal?: boolean;
};

export const menuItems: MenuItem[] = [
  {
    name: 'Calendar',
    to: PATH.calendar,
  },
  {
    name: 'Instructions',
    to: `${PATH.instructions}?${instructionSlide2QueryString}`,
  },
  {
    name: 'Settings',
    to: PATH.settings,
  },
  {
    name: 'Contact Us',
    to: EXTERNAL_LINKS.contactUs,
    isExternal: true,
  },
  {
    name: 'Donate',
    to: EXTERNAL_LINKS.donate,
    isExternal: true,
  },
  {
    name: 'Partner with AWMI',
    to: EXTERNAL_LINKS.partnerWithAWMI,
    isExternal: true,
  },
  {
    name: 'Purchase Living Commentary',
    to: EXTERNAL_LINKS.purchaseLC,
    isExternal: true,
  },
  {
    name: 'Free Multimedia Teachings',
    to: EXTERNAL_LINKS.freeMultimediaTeachings,
    isExternal: true,
  },
];
