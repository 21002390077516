import { useContext } from 'react';
import { CalendarContext } from '../contexts/Calendar';

const useCalendar = () => {
  const context = useContext(CalendarContext);

  if (!context)
    throw new Error('Calendar context must be use inside CalendarProvider');

  return context;
};

export default useCalendar;
