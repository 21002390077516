import { useEffect, useRef } from "react";
import useSettings from "../../hooks/useSettings";
import { browserName } from "react-device-detect";
import useScreenSize from "../../hooks/useScreenSize";

interface SliderSeekerProps {
    className?: string;
    initialPercentage?: number;
    onChange?: (percentage: number) => void;
}

const SLIDER_HANDLE_WIDTH = 24; //px

const SliderSeeker = ({ className, initialPercentage = 0, onChange }: SliderSeekerProps) => {
    const sliderBaseRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);
    const isSlidingRef = useRef(false);
    const { fontSize, setFontSize } = useSettings();
    const { screenSize } = useScreenSize();

    useEffect(() => {
        if ((browserName === "Firefox" || browserName === "Safari") && screenSize > 768) {
        } else {
            const sliderBaseWidth = sliderBaseRef.current?.getBoundingClientRect().width || 0;
            const sliderBaseOffsetLeft = sliderBaseRef.current?.getBoundingClientRect().left || 0;

            const moveTo = (to: number) => {
                const adjustedTo = to < SLIDER_HANDLE_WIDTH ? to : to + SLIDER_HANDLE_WIDTH / 2;

                const positionLeft = Math.min(Math.max(adjustedTo, SLIDER_HANDLE_WIDTH), sliderBaseWidth);

                if (sliderRef.current) {
                    sliderRef.current.style.width = positionLeft + "px";
                }

                if (onChange) {
                    onChange(positionLeft / sliderBaseWidth);
                }
            };

            moveTo(initialPercentage * sliderBaseWidth);

            const startTouchOrMouse = (e: TouchEvent | MouseEvent) => {
                const at = e instanceof TouchEvent ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;

                const touchedAt = at - sliderBaseOffsetLeft;
                moveTo(touchedAt);
                isSlidingRef.current = true;
            };

            const moveTouchOrMouse = (e: TouchEvent | MouseEvent) => {
                const at = e instanceof TouchEvent ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;

                if (isSlidingRef.current && sliderRef.current) {
                    const touchedAt = at - sliderBaseOffsetLeft;
                    moveTo(touchedAt);
                }
            };

            const endTouchOrMouse = () => {
                isSlidingRef.current = false;
            };

            const option = { passive: true };
            sliderBaseRef.current?.addEventListener("touchstart", startTouchOrMouse, option);
            sliderBaseRef.current?.addEventListener("mousedown", startTouchOrMouse, option);

            document.addEventListener("touchmove", moveTouchOrMouse, { passive: true });
            document.addEventListener("mousemove", moveTouchOrMouse, { passive: true });

            document.addEventListener("touchend", endTouchOrMouse, { passive: true });
            document.addEventListener("mouseup", endTouchOrMouse, { passive: true });

            const sliderRefCopy = sliderRef.current;

            return () => {
                sliderRefCopy?.removeEventListener("touchstart", startTouchOrMouse);
                sliderRefCopy?.removeEventListener("mousedown", startTouchOrMouse);

                document.removeEventListener("touchmove", moveTouchOrMouse);
                document.removeEventListener("mousemove", moveTouchOrMouse);

                document.removeEventListener("touchend", endTouchOrMouse);
                document.removeEventListener("mouseup", endTouchOrMouse);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, []);

    return (
        <>
            {(browserName === "Firefox" || browserName === "Safari") && screenSize > 768 ? (
                <div className="range-input">
                    <input
                        type="range"
                        value={fontSize}
                        min="0.800"
                        max="1.5"
                        className="w-full h-7 bg-primary-lighter border-gray-200 border-2 rounded-full appearance-none cursor-pointer"
                        onChange={(e) => setFontSize(parseFloat(e.target.value))}
                        step="0.01"
                    />
                </div>
            ) : (
                <div
                    className={`${className} relative rounded-full w-full h-6 border border-primary `}
                    ref={sliderBaseRef}
                >
                    <div className="h-full relative bg-primary-light rounded-full" ref={sliderRef}>
                        <div className="w-6 h-6 absolute rounded-full bg-white border border-primary top-[-1px] right-0" />
                    </div>
                </div>
            )}
        </>
    );
};

export default SliderSeeker;
