import { createContext, useState, useEffect } from "react";
import { ScreenSizeContextTypes } from "../types";

//Creating a context for the screen size to check if the user
//is on a desktop or mobile device
//The context is used through the useScreenSize hook
export const ScreenSizeContext = createContext<ScreenSizeContextTypes | null>(null);

export default function ScreenSizeProvider({ children }: { children: React.ReactNode }) {
    //If you want to use the screen size in this context uncomment the following line
    //but make sure to change the dependency array in the useEffect hook
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        function handleResize() {
            setScreenSize(window.innerWidth);
            if (screenSize > 1025) {
                setIsDesktop(true);
            } else if (screenSize < 1025) {
                setIsDesktop(false);
            }
        }
        handleResize();
        //Using an event listener to check if the user has resized the window
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [screenSize]);
    //Returning the context provider with the value of isDesktop
    //The provider will be used in the App.tsx file
    return <ScreenSizeContext.Provider value={{ isDesktop, screenSize }}>{children}</ScreenSizeContext.Provider>;
}
