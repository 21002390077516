interface AppScaffoldProps {
  children: React.ReactNode;
}

const AppScaffold = ({ children }: AppScaffoldProps) => {
  return (
    <div className="max-w-screen-md mx-auto h-full bg-slate-50">{children}</div>
  );
};

export default AppScaffold;
