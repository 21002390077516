import React from 'react';

interface PageScaffoldProps {
  /**
   * page title
   */
  header: string;
  /**
   * body of the page
   */
  children: React.ReactNode;

  /**
   * Optional additional class name to customize it further
   */
  className?: string;
}

const PageScaffold = ({
  header,
  children,
  className = '',
}: PageScaffoldProps) => {
  return (
    <div className={`w-full min-h-screen overflow-auto bg-white  ${className}`}>
      <div className="pt-16 pb-7 flex items-center justify-center font-avenir-demibold text-[2.5em] text-primary shrink-0">
        {header}
      </div>
      {children}
    </div>
  );
};

export default PageScaffold;
