import { IconProps } from './IconProps';

interface MinusFilledIconProps extends IconProps {}

const MinusFilledIcon = ({ className }: MinusFilledIconProps) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 52 52"
    className={className || 'fill-primary-light'}
  >
    <path
      d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M38.5,28h-25c-1.104,0-2-0.896-2-2
s0.896-2,2-2h25c1.104,0,2,0.896,2,2S39.604,28,38.5,28z"
    />
  </svg>
);

export default MinusFilledIcon;
