import MonthCell from '../../components/calendar/MonthCell';
import { months } from '../../data/months';
import { MonthData } from '../../types';

interface MonthsListProps {
  selectedMonth: MonthData | undefined;
  onSelectMonth: (month: MonthData) => void;
}

const MonthsList = ({ selectedMonth, onSelectMonth }: MonthsListProps) => {
  const rows = [months.slice(0, 6), months.slice(6)];

  return (
    <div className="bg-primary-lighter/[0.33] p-3 flex flex-col items-center justify-center gap-1">
      {rows.map((rowMonths, index) => (
        <div
          className="flex items-center justify-center w-full h-[60px] shrink-0 "
          key={index}
        >
          {rowMonths.map(month => (
            <div
              key={month.shortName}
              className=" w-[60px] flex align-center justify-center"
            >
              <MonthCell
                month={month.shortName}
                variant={
                  selectedMonth?.shortName.toLowerCase() ===
                  month.shortName.toLocaleLowerCase()
                    ? 'bold'
                    : 'muted'
                }
                onClick={() => {
                  onSelectMonth(month);
                }}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MonthsList;
