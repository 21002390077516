import logo from '../../assets/awm-logo.png';
import awImage from '../../assets/andrew-image.svg';
import SlideScaffold from './SlideScaffold';

const Slide3 = () => {
  return (
    <SlideScaffold className="flex flex-col">
      <div
        className="font-klinic-slab-book text-primary-light "
        style={{ fontSize: '2.5rem' }}
      >
        Andrew Wommack
      </div>

      <div className="my-3 flex items-end gap-2">
        <img
          src={awImage}
          alt="Andrew Wommack"
          className="inline-block w-16 h-16"
        />
        <div className="text-black text-sm klinic-slab-book ">
          Founder, President of Andrew Wommack Ministries Woodland Park,
          Colorado
        </div>
      </div>
      <div className="my-8 text-lg">
        <p className="mb-1">
          Faith comes by hearing and hearing by the Word of God. Romans 10:17.
        </p>
        This reading plan is a collection of Andrew's 50-plus years of
        revelation and wisdom in reading God's Word. Thanks to the generosity of
        our partners, this resource was created and made available to you
        completely free. We pray it will help you grow closer to God as you
        study His Word this year.
      </div>

      <div className="mx-auto mt-8">
        <img
          src={logo}
          alt="andrew wommack minstry's logo"
          className=" inline-block w-[140px]"
        />
      </div>
    </SlideScaffold>
  );
};

export default Slide3;
