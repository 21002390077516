import { useNavigate } from 'react-router-dom';
import { LeftChevronIcon, RightChevronIcon } from '../../assets/icons';
import DateCell from '../../components/calendar/DateCell';
import {
  getDateOfTheYear,
  isFirstDayOfTheYear,
  isLastDayOfTheYear,
} from '../../data/months';
import GTM from '../../GTM';
import useBibleContent from '../../hooks/useBibleContent';
import useCalendar from '../../hooks/useCalendar';
import { PATH } from '../../routes/paths';
import { IBook } from '../../types';

interface DateNavigatorButtonProps {
  icon: React.ReactNode;
  label: String;
  disabled?: boolean;
  onClick: () => void;
}

const DateNavigatorButton = ({
  icon,
  label,
  disabled = false,
  onClick,
}: DateNavigatorButtonProps) => {
  return (
    <button
      className={`flex flex-col items-center px-4 py-3 disabled:opacity-50`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="flex rouned-full w-12 h-12 items-center justify-center bg-primary-lighter rounded-full">
        {icon}
      </div>

      <div className="text-white mt-4 font-avenir-demibold text-xs">
        {label}
      </div>
    </button>
  );
};

const DateNavigator = () => {
  const navigate = useNavigate();
  const { selectedMonth, selectedDate, nextDate, previousDate } = useCalendar();
  const { loading, newTestament, oldTestament } = useBibleContent();

  const onClickDate = () => {
    navigate(PATH.calendar);
  };

  const getFormattedTitle = (book: IBook) => {
    return `${book.title.toUpperCase()} ${book.chapterStart}${
      book.chapterEnd ? '-' + book.chapterEnd : ''
    }`;
  };

  const showBooksInfo = !loading && !!newTestament && !!oldTestament;

  return (
    <div className="bg-primary-darker py-4 flex items-center text-white ">
      <div className="border-white border-r">
        <DateNavigatorButton
          icon={<LeftChevronIcon />}
          label="PREVIOUS"
          disabled={isFirstDayOfTheYear(selectedMonth.shortName, selectedDate)}
          onClick={() => {
            previousDate();
            GTM.notifyClickPreviousDate(selectedMonth, selectedDate);
          }}
        />
      </div>
      <div className="flex-grow flex flex-col items-center justify-center ">
        <DateCell
          date={selectedDate}
          month={selectedMonth.name}
          size="small"
          onClick={onClickDate}
        />

        {showBooksInfo ? (
          <div className="text-md text-lg text-center mt-2 px-1">
            <span className="whitespace-nowrap">
              {getFormattedTitle(oldTestament)}
            </span>
            {' • '}
            <span className="whitespace-nowrap">
              {getFormattedTitle(newTestament)}
            </span>
          </div>
        ) : (
          <div className="text-center my-2">Loading...</div>
        )}

        <div
          className="text-lg  text-primary-light"
          style={{ marginTop: '-5px' }}
        >
          Day {getDateOfTheYear(selectedMonth.shortName, selectedDate)}
        </div>
      </div>
      <div className="border-white border-l">
        <DateNavigatorButton
          icon={<RightChevronIcon />}
          label="NEXT"
          disabled={isLastDayOfTheYear(selectedMonth.shortName, selectedDate)}
          onClick={() => {
            nextDate();
            GTM.notifyClickNextDate(selectedMonth, selectedDate);
          }}
        />
      </div>
    </div>
  );
};

export default DateNavigator;
