import { useEffect, useState } from 'react';
import { IVerse } from '../../../types';
import { ToggleVerseCommentary } from './ToggleVerseCommentary';

interface VerseProps {
  verse: IVerse;
  shouldExpand?: boolean;
  showExpandCollapseCtrl?: boolean;
  fontSize: number; // in rem
}

const Verse = ({
  verse,
  shouldExpand = true,
  showExpandCollapseCtrl = true,
  fontSize,
}: VerseProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(shouldExpand);
  }, [shouldExpand]);

  const allComments = verse.LCComents.concat(verse.LFTComments);
  const hasAnyComment = allComments.length > 0;

  return (
    <div
      className="w-full"
      style={{
        fontSize: fontSize + 'rem',
      }}
    >
      <div className=" flex gap-10">
        <div className="flex-grow ">
          <span className=" align-super inline-block mr-2 text-xs">
            {verse.index}
          </span>
          {verse.content}
        </div>

        {showExpandCollapseCtrl && (
          <div className="shrink-0">
            <ToggleVerseCommentary
              isExpanded={isExpanded}
              disabled={!hasAnyComment}
              toggle={() => {
                setIsExpanded(prev => !prev);
              }}
            />
          </div>
        )}
      </div>

      {isExpanded && hasAnyComment && (
        <div className="mt-4">
          {allComments.map((comment, index) => (
            <div className="mt-3 italic text-med-blue" key={index}>
              {comment}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Verse;
