import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BibleContentProvider from "./contexts/BibleContent";
import CalendarProvider from "./contexts/Calendar";
import SettingsProvider from "./contexts/Settings";
import ScreenSizeProvider from "./contexts/ScreenSize";
import AppScaffold from "./layouts/AppScaffold";
import { appRoutes } from "./routes";

const router = createBrowserRouter(appRoutes);

function App() {
    return (
        <ScreenSizeProvider>
            <SettingsProvider>
                <CalendarProvider>
                    <BibleContentProvider>
                        <AppScaffold>
                            <RouterProvider router={router} />
                        </AppScaffold>
                    </BibleContentProvider>
                </CalendarProvider>
            </SettingsProvider>
        </ScreenSizeProvider>
    );
}

export default App;
