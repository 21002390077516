import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../../components/ui/Button";
import { instructionSlide2QueryString, PATH } from "../../routes/paths";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import useScreenSize from "../../hooks/useScreenSize";

const slidesCount = 3;

const Introduction = () => {
    /* State and Refs */
    const sliderRef = useRef<HTMLDivElement>(null);
    const isSlidingRef = useRef(false);

    const navigate = useNavigate();
    let { search } = useLocation();

    const [index, setIndex] = useState(search.includes(instructionSlide2QueryString) ? 1 : 0);

    //Checking the screen size to determine if the slider should be enabled
    //also adding a button if the screen is a laptop or desktop
    const { isDesktop } = useScreenSize();

    useEffect(() => {
        if (!isDesktop) {
            const moveMultiplier = 2;
            const sliderWidth = sliderRef.current?.getBoundingClientRect().width || 0;
            const minimumPositionLeft = -(slidesCount - 1) * sliderWidth; // 3rd slide
            const maximumPositionLeft = 0; // first slide

            let sliderPositionLeft = 0;

            let nextMinimumPositionLeft = 0;
            let nextMaximumPositionLeft = 0;

            let touchStartX = 0;
            let movedX = 0;

            const getAdjustedPosition = (movedBy: number) => {
                const adjustedPositionLeft = Math.min(
                    Math.max(sliderPositionLeft + movedBy, nextMinimumPositionLeft),
                    nextMaximumPositionLeft
                );

                return adjustedPositionLeft;
            };

            const setPositionLeft = (movedBy: number, animate: boolean = false) => {
                if (sliderRef.current) {
                    if (animate) {
                        sliderRef.current.style.transitionDuration = "300ms";
                        setTimeout(() => {
                            if (sliderRef.current) sliderRef.current.style.transitionDuration = "0ms";
                        }, 300);
                    }

                    const adjustedPositionLeft = getAdjustedPosition(movedBy);
                    sliderRef.current.style.transform = `translate3d(${adjustedPositionLeft}px, 0px, 0px)`;
                }
            };

            const updateIndex = (index: number) => {
                nextMinimumPositionLeft = Math.max(nextMinimumPositionLeft - sliderWidth, minimumPositionLeft);
                nextMaximumPositionLeft = Math.min(nextMaximumPositionLeft + sliderWidth, maximumPositionLeft);

                sliderPositionLeft = -sliderWidth * index;
                movedX = 0;

                setPositionLeft(0, true);
                setIndex(index);
            };

            updateIndex(index);

            const startTouchOrMouse = (e: TouchEvent | MouseEvent) => {
                const at = e instanceof TouchEvent ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;

                isSlidingRef.current = false;
                setTimeout(() => {
                    isSlidingRef.current = true;
                }, 50);
                touchStartX = at;
            };

            const moveTouchOrMouse = (e: TouchEvent | MouseEvent) => {
                const at = e instanceof TouchEvent ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;

                if (isSlidingRef.current && sliderRef.current) {
                    movedX = Math.min(Math.max((at - touchStartX) * moveMultiplier, -sliderWidth), sliderWidth);
                    setPositionLeft(movedX);
                }
            };

            const endTouchOrMouse = () => {
                if (!isSlidingRef.current) return;
                isSlidingRef.current = false;
                const closestValueIndex = Math.round(Math.abs(getAdjustedPosition(movedX)) / sliderWidth);
                updateIndex(closestValueIndex);
            };

            const option = { passive: true };
            sliderRef.current?.addEventListener("touchstart", startTouchOrMouse, option);
            sliderRef.current?.addEventListener("mousedown", startTouchOrMouse, option);

            sliderRef.current?.addEventListener("touchmove", moveTouchOrMouse, option);
            sliderRef.current?.addEventListener("mousemove", moveTouchOrMouse, option);

            sliderRef.current?.addEventListener("touchend", endTouchOrMouse, option);
            sliderRef.current?.addEventListener("mouseup", endTouchOrMouse, option);

            const sliderRefCopy = sliderRef.current;

            return () => {
                sliderRefCopy?.removeEventListener("touchstart", startTouchOrMouse);
                sliderRefCopy?.removeEventListener("mousedown", startTouchOrMouse);

                sliderRefCopy?.removeEventListener("touchmove", moveTouchOrMouse);
                sliderRefCopy?.removeEventListener("mousemove", moveTouchOrMouse);

                sliderRefCopy?.removeEventListener("touchend", endTouchOrMouse);
                sliderRefCopy?.removeEventListener("mouseup", endTouchOrMouse);
            };
        } else {
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop]);

    /* Functions */
    const transformToNextSlide = (incrementIndex: number) => {
        let translateToNextSlide = -768 * incrementIndex;
        let translateToNextSlideString = translateToNextSlide.toString() + "px";
        if (sliderRef.current) {
            sliderRef.current.style.transform = `translate3d(${translateToNextSlideString}, 0px, 0px)`;
        }
    };

    /* Components */
    const FooterDesktopScreen = () => {
        return (
            <div className="mt-3 mb-2">
                {index < slidesCount - 1 ? (
                    <Button
                        variant="filled"
                        className="px-4 w-[180px]"
                        onClick={() => {
                            setIndex(index + 1);
                            transformToNextSlide(index + 1);
                        }}
                    >
                        Next Page
                    </Button>
                ) : (
                    <div>
                        <Button variant="filled" className="px-4 w-[180px]" onClick={navigateHome}>
                            Get Started
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const FooterMobileScreen = () => {
        return (
            <div className="mt-3 mb-2">
                {index < slidesCount - 1 ? (
                    <div className="text-primary-darker text-lg">Swipe left for instructions Mobile</div>
                ) : (
                    <div>
                        <Button variant="filled" className="px-4 w-[180px]" onClick={navigateHome}>
                            Get Started
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const navigateHome = () => {
        navigate(PATH.root);
    };

    return (
        <div className="w-full h-screen overflow-hidden relative introduction-page">
            <button onClick={navigateHome} className="absolute right-8 top-8 z-10 bg-white rounded-full p-1 shadow-sm">
                <CrossIcon stroke={"black"} />
            </button>

            <div className="flex flex-col items-center h-full pb-10">
                <div
                    className=" flex-grow w-full h-full flex"
                    ref={sliderRef}
                    style={{
                        height: "calc(100vh - 130px)",
                    }}
                >
                    <Slide1 />
                    <Slide2 />
                    <Slide3 />
                </div>

                <div className="my-4 gap-1 flex">
                    {new Array(3).fill(undefined).map((_, i) => (
                        <span
                            key={i}
                            className={`inline-block w-2 h-2 rounded-full ${
                                i === index ? "bg-primary-light" : "bg-primary-darker"
                            }`}
                        ></span>
                    ))}
                </div>
                {/* footer with actions */}
                {isDesktop ? <FooterDesktopScreen /> : <FooterMobileScreen />}
            </div>
        </div>
    );
};

export default Introduction;
