import { useRef, useState } from 'react';
import Button from '../../components/ui/Button';
import useBibleContent from '../../hooks/useBibleContent';
import { EXTERNAL_LINKS } from '../../routes/paths';
import Chapter from './Chapter';
import DateNavigator from './DateNavigator';
import SwipeCommentaryToggler from './SwipeCommentaryToggler';
import TestamentTab from './TestamentTab';

const Reading = () => {
  const scrollToTopRef = useRef<HTMLDivElement>(null);

  const { loading, newTestament, oldTestament, error } = useBibleContent();
  const [isOldTestament, setIsOldTestament] = useState(true);
  const [allExpanded, setAllExpanded] = useState(false);

  const scrollToTop = () => {
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const showBook = !loading && !!newTestament && !!oldTestament;
  const book = isOldTestament ? oldTestament : newTestament;

  return (
    <div className="overflow-auto" ref={scrollToTopRef}>
      <a
        href={EXTERNAL_LINKS.purchaseLC}
        className="block text-center py-3 bg-primary-light text-white text-sm font-light"
        target="_blank"
        rel="noreferrer"
      >
        PURCHASE ANDREW'S LIVING COMMENTARY
      </a>

      <DateNavigator />

      {showBook && !!book ? (
        <>
          <TestamentTab
            isOldTestament={isOldTestament}
            setIsOldTestament={setIsOldTestament}
          />

          <SwipeCommentaryToggler
            isExpanded={allExpanded}
            setIsExpanded={isExpanded => setAllExpanded(isExpanded)}
          />

          <div className="flex flex-col gap-4">
            {book.chapters.map(chapter => (
              <Chapter
                key={chapter.index}
                allExpanded={allExpanded}
                book={book.title}
                chapter={chapter}
              />
            ))}
          </div>

          <div className="flex flex-col items-center mt-8">
            {isOldTestament && (
              <Button
                variant={'filled'}
                onClick={() => {
                  setIsOldTestament(false);
                  scrollToTop();
                }}
                className="px-1 py-3"
              >
                Go to today's New Testament Reading
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="h-[600px] text-center pt-4">
          {loading ? 'Loading' : error}{' '}
        </div>
      )}
    </div>
  );
};

export default Reading;
