import PageScaffold from '../../layouts/PageScaffold';
// import BibleTranslation from './BibleTranslation';
import FontSize from './FontSize';

const Settings = () => {
  return (
    <PageScaffold
      header="Settings"
      className="flex flex-col gap-8 px-6 pb-6 bg-gray-50"
    >
      {/* <BibleTranslation /> */}
      <FontSize />
    </PageScaffold>
  );
};

export default Settings;
