const RightChevronIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.319"
      height="26.638"
      viewBox="0 0 14.319 26.638"
    >
      <path
        id="chevron-left"
        d="M20.9,29.81,9,17.9,20.9,6"
        transform="translate(22.319 31.224) rotate(180)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default RightChevronIcon;
