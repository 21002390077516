import Card from '../../components/ui/Card';

interface SettingCardProps {
  children: React.ReactNode;
  title: string;
}
const SettingCard = ({ children, title }: SettingCardProps) => {
  return (
    <Card variant="outlined" className=" p-8 pb-12">
      <div className="text-primary-light font-avenir-demibold text-center text-3xl mb-4">
        {title}
      </div>

      {children}
    </Card>
  );
};

export default SettingCard;
