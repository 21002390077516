import apiConfig from '../config';
import { getMMDD } from '../data/months';
import {
  ContactUsFormData,
  GetDayReadingAPIResponse,
  GetDayReadingResponse,
  IBook,
  IChapter,
  IVerse,
  MonthData,
  TestamentDataAPIResponse,
} from '../types';

const getResourceUrl = (month: MonthData, date: number) => {
  const dateFilter = getMMDD(month, date); // example 0116 for January 16
  const url = `${apiConfig.api_base_url}?date=${dateFilter}`;
  return url;
};

export async function getDaysReading(
  month: MonthData,
  date: number
): Promise<GetDayReadingResponse | undefined> {
  const response = await window.fetch(getResourceUrl(month, date));
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const d = await response.json();
  const data = d as GetDayReadingAPIResponse;

  const getBook = (response: TestamentDataAPIResponse) => {
    const LCComents = response.LCComments;
    const LFTComments = response.LFTComments;

    const chapters: IChapter[] = [];
    for (let chapterIndex in response.verse) {
      const chapterData = response.verse[chapterIndex];
      const verses: IVerse[] = [];
      for (const [verseIndex, verseContent] of Object.entries(chapterData)) {
        verses.push({
          index: verseIndex,
          content: verseContent,
          LCComents: (LCComents[chapterIndex] || {})[verseIndex] || [],
          LFTComments: (LFTComments[chapterIndex] || {})[verseIndex] || [],
        });
      }

      chapters.push({
        index: chapterIndex,
        verses: verses,
      });
    }

    const book: IBook = {
      title: response.book,
      chapterStart: response.chapter_start,
      chapterEnd: response.chapter_end,
      chapters: chapters,
    };

    return book;
  };

  return {
    day: data.day,
    oldTestament: getBook(data.old_testament),
    newTestament: getBook(data.new_testament),
  };
}

export const submitContactUsForm = async (data: ContactUsFormData) => {
  const body = { ...data, access_key: apiConfig.contact_us_api_key };
  const response = await fetch(apiConfig.contact_us_api_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const d = await response.json();

  if (!response.ok && !d) {
    throw new Error(response.statusText);
  }

  return { success: Boolean(d.success), message: d.message as string };
};
