import {
  FacebookIcon,
  GlobeIcon,
  InstagramIcon,
  MailIcon,
  PhoneIcon,
  TwitterIcon,
} from '../../assets/icons';
import { EXTERNAL_LINKS } from '../../routes/paths';

const Footer = () => {
  return (
    <div className="bg-primary-darker p-10 text-white font-avenir-demibold">
      <div className="flex flex-col gap-5">
        <div className="flex items-center">
          <MailIcon width="27px" />
          <a className="block flex-grow ml-6" href={EXTERNAL_LINKS.awmiMail}>
            info@awmi.net
          </a>
        </div>

        <div className="flex items-center">
          <PhoneIcon width="27px" />
          <a className="block flex-grow ml-6" href={EXTERNAL_LINKS.awmiPhone}>
            (719) 635-1111
          </a>
        </div>

        <div className="flex items-center">
          <GlobeIcon width="27px" />
          <a
            className="block flex-grow ml-6"
            href={EXTERNAL_LINKS.awmi}
            target="_blank"
            rel="noreferrer"
          >
            https://www.awmi.net
          </a>
        </div>
      </div>

      <hr className="my-8 h-px bg-primary-light/80 border-0 " />

      <div className="flex items-center gap-5">
        <a
          href={EXTERNAL_LINKS.instagram}
          className="block"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon width="30px" height="30px" className="fill-white" />
        </a>
        <a
          href={EXTERNAL_LINKS.facebook}
          className="block"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon height="30px" className="fill-white" />
        </a>
        <a
          href={EXTERNAL_LINKS.twitter}
          className="block"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon height="30px" className="fill-white" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
